<template>
 <div id="Invoice">
  <div class="header-invoice">
   <b-container>
    <b-row>
     <b-col>
      <div class="contianer-logo-name-company">
       <div class="logo-company">
        <img src="../assets/logo-grediant.png" alt="logo-company" />
       </div>
      </div>
     </b-col>
     <b-col
      ><div class="name-invoice">
       <h2>Invoice</h2>
      </div></b-col>
    </b-row>
   </b-container>
  </div>
  <div class="DetailsAboutBuyer">
   <b-container>
    <b-row>
     <b-col>
      <div class="Detailes-person">
       <span>Invoice To</span>
       <h4>Abd Alshafie Faraj Almajdoup</h4>
       <p class="homeAdress">libya - tripoli</p>
       <p>Tajoura Almarsa</p>
       <br />
       <p class="phone">+218911279177</p>
       <p class="email">abdalshafiealmajdoup@gmail.com</p>
       <br>
       <span class="prntPdf" v-on:click="makePDF">PDF <i class="far fa-file-pdf"></i></span> <span class="prntPdf" v-on:click="makePRINT">Print<i class="fas fa-print"></i></span>
      </div>
     </b-col>
     <b-col>
      <div class="Detailes-Invoice">
       <h3 class="totalDue">Total Due: <Strong> 3690 DLY</Strong></h3>
       <h3 class="number-invoce">Number Invoce: <Strong> #26598 </Strong></h3>
       <h3 class="number-invoce">Account No: <Strong> 1026 </Strong></h3>
       <p class="invoiceStatus">Invoice Status <strong>Unpaid</strong></p>
       <p class="InvoiceDate">Invoice Date <strong>18/01/2022</strong></p>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div class="table-products">
   <b-container>
    <b-row>
     <b-col>
      <table>
       <thead>
        <tr>
         <th>No</th>
         <th>Name Packges</th>
         <th>Name product</th>
         <th>Subscription period</th>
         <th>Product Price</th>
        </tr>
       </thead>
       <tbody>
        <tr>
         <td>1</td>
         <td>Web Hosting</td>
         <td>Shared Hosting</td>
         <td>2 yearly</td>
         <td>16000 DLY</td>
        </tr>
        <tr>
         <td>2</td>
         <td>Cloud</td>
         <td>Cloud Storage</td>
         <td>1 yearly</td>
         <td>13000 DLY</td>
        </tr>
        <tr>
         <td>3</td>
         <td>Cloud</td>
         <td>Managed Services Support</td>
         <td>1 yearly</td>
         <td>9000 DLY</td>
        </tr>
        <tr>
         <td>4</td>
         <td>Web Hosting</td>
         <td>Virtual Privet server (VPS)</td>
         <td>6 Monthly</td>
         <td>2300 DLY</td>
        </tr>
        <tr>
         <td>5</td>
         <td>Cloud</td>
         <td>Elastic Could Services</td>
         <td>1 yearly</td>
         <td>23000 DLY</td>
        </tr>
       </tbody>
      </table>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div class="details-company">
   <b-container>
    <b-row>
     <b-col>
      <div class="about-info">
       <div class="aboutCompny">
        <h4>Contact Us</h4>
        <p>Phone :<strong>00218911279177 - 00218923432581</strong></p>
        <p>Email :<strong>1stvault.info@transahara.com</strong></p>
       </div>
      </div>
     </b-col>
     <b-col>
      <div class="detailes-price">
       <div class="container-price">
        <p>Wallet BalanceL</p>
        <strong>50000 DLY</strong>
       </div>
       <div class="container-price">
        <p>Discount</p>
        <strong>-241 DLY</strong>
       </div>
       <div class="total">
        <h5>GRAND TOTAL</h5>
        <h2>26000 DLY</h2>
       </div>
      </div></b-col
     >
    </b-row>
   </b-container>
  </div>
 </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
 name: "Invoice",
 methods: {
  makePDF() {
   window.html2canvas = html2canvas;
   var doc = new jsPDF("p", "pt", "a4");
   doc.html(document.querySelector("#app"), {
    callback: function (pdf) {
     pdf.save("1stVault.pdf");
    },
   });
  },
  makePRINT(){
   window.print();
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#Invoice {
 @mixin style-headers {
  padding: 10px 20px;
  background: map-get($branding, them-color-option-tow);
  border-radius: 2px;
 }
 @mixin style-text-header {
  color: blanchedalmond;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
 }
 @mixin style-icon-befor-header {
  margin-right: 5px;
  font-size: 19px;
 }
 @mixin style-list {
  font-size: 15px;
  border-bottom: 1px solid #f1ebeb;
  padding: 10px;
  margin-bottom: 0;
  color: #5b5858;
 }
 @mixin product-info {
  box-shadow: 0 0 13px 0 #00000042;
  margin-top: 10px;
  padding: 20px 15px;
  background-color: #fafafa;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #efefef;
  margin: 8px 0 10px 0;
 }
 @mixin text-in-span-summary {
  font-size: 13px;
  color: #000;
  font-weight: 600;
 }
 @mixin hedding {
  margin: 7px 0;
  font-size: 26px;
  font-family: $Headings-font;
  font-weight: 400;
  color: map-get($color-texts, font-list);
  text-transform: capitalize;
 }
 @mixin pragraph {
  margin: 4px 0;
  font-size: 16px;
  font-family: $Paragraph-font;
  font-weight: 500;
  color: map-get($color-texts, font-color-havef);
 }
 .header-invoice {
  background-color: #1c2746;
  padding: 10px 0;
  .contianer-logo-name-company {
   .logo-company {
    img {
     width: 35%;
    }
   }
  }
  .name-invoice {
   h2 {
    font-weight: 800;
    font-size: 30px;
    color: #008aff;
    font-family: "Montserrat", sans-serif !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: right;
    margin: 0;
   }
  }
 }
 .DetailsAboutBuyer {
  padding: 20px 0;

  .Detailes-person {

   span {
    margin: 4px 0;
    font-size: 14px;
    font-family: $Paragraph-font;
    font-weight: 500;
    color: map-get($color-texts, font-color-havef);
   }
   h4 {
    margin: 7px 0;
    font-size: 23px;
    font-family: $Headings-font;
    font-weight: 400;
    color: map-get($color-texts, font-list);
    text-transform: capitalize;
   }
   p {
    margin: 4px 0;
    font-size: 14px;
    font-family: $Paragraph-font;
    font-weight: 500;
    color: map-get($color-texts, font-color-havef);
   }
   .prntPdf{
    margin: 3px;
    cursor: pointer;
    padding: 6px 10px;
    color: blanchedalmond;
    border-radius: 2px;
    background-color: #1c2746; 
    .fa-file-pdf,.fa-print{
     margin: 0 2px;
    }
   }
  }
  .Detailes-Invoice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
   .totalDue {
    margin: 7px 0;
    font-size: 22px;
    font-family: $Paragraph-font;
    font-weight: 400;
    color: map-get($color-texts, font-list);
    text-transform: capitalize;
    strong {
     font-size: 20px;
     font-family: $Numbers-Price;
     font-weight: 800;
    }
    padding-bottom: 5px;
    border-bottom: 1px solid map-get($color-texts, font-list);
   }

   .number-invoce {
    margin: 7px 0;
    font-size: 20px;
    font-family: $Paragraph-font;
    font-weight: 400;
    color: map-get($color-texts, font-list);
    text-transform: capitalize;
    strong {
     margin: 4px 0;
     color: map-get($color-texts, font-color-havef);
     font-size: 20px;
     font-family: $Headings-font;
     font-weight: 800;
    }
   }
   .invoiceStatus {
    margin: 7px 0;
    font-size: 20px;
    font-family: $Paragraph-font;
    font-weight: 400;
    color: map-get($color-texts, font-list);
    text-transform: capitalize;
    strong {
     margin: 4px 0;
     color: map-get($color-texts, font-color-havef);
     font-size: 20px;
     font-family: $Numbers-font;
     font-weight: 800;
     color: #ed5e6c;
    }
   }
   .InvoiceDate {
    margin: 7px 0;
    font-size: 20px;
    font-family: $Paragraph-font;
    font-weight: 400;
    color: map-get($color-texts, font-list);
    text-transform: capitalize;
    strong {
     margin: 4px 0;
     color: map-get($color-texts, font-color-havef);
     font-size: 20px;
     font-family: $Headings-font;
     font-weight: 800;
    }
   }
  }
 }
 .table-products {
  table {
   width: 100%;
   thead {
    tr {
     background-color: #1c2746;
     th {
      padding: 11px 9px;
      text-align: left;
      text-transform: capitalize;
      font-family: $Headings-font;
      font-size: 18px;
      color: blanchedalmond;
     }
    }
   }
   tbody {
    tr {
     background-color: #f0f0f0;
     border-bottom: 1px solid #ccc;
     td {
      padding: 11px 9px;
      text-align: left;
      text-transform: capitalize;
      font-family: $Paragraph-font;
      font-size: 15px;
      color: map-get($color-texts, font-list);
     }
    }
   }
  }
 }
 .details-company {
  padding: 7px 0;
  .about-info {
   .aboutCompny {
    h4 {
     @include hedding;
    }
    p {
     @include pragraph;
    }
   }
  }
  .detailes-price {
   text-align: right;
   .container-price {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    p {
     @include pragraph;
    }
    strong {
     @include hedding;
    }
   }
   .total {
    margin: 5px 0;
    text-align: center;
    h5 {
     @include hedding;
     color: #212529b5;
    }
    h2 {
     @include hedding;
    }
   }
  }
 }
}
</style>
