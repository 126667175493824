<template>
 <div id="Invoice">
  <Invoice/>
 </div>
</template>

<script>
import Invoice from "../components/Invoice.vue";
 export default {
    components: { Invoice }
}
</script>

<style lang="sass" scoped>

</style>